// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/max-dependencies */
import { ExternalLink, ModalStatus, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, CircularProgress, LinearProgress, Stack } from "@mui/material";
import { useWorkerPreferences } from "@src/appV2/Agents/api/useWorkerPreferences";
import { useGetAttendanceScoreProfile } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { getScoreType } from "@src/appV2/AttendanceScore/utils";
import { OpenCase } from "@src/appV2/ExperimentalHomeHealth/FindCases/OpenCase";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { Banner, CardAlert } from "@src/appV2/lib/Alert";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { useSearchParams } from "@src/appV2/lib/utils/useSearchParams";
import { OpenShiftFilters } from "@src/appV2/OpenShifts/components/OpenShiftCalendar/OpenShiftFilters";
import { useReliabilityRankingExperiment } from "@src/appV2/ReliabilityRank/useReliabilityRankExperiment";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { getTheme } from "../../lib/Theme/theme";
import { HOME_HEALTH_USER_EVENTS } from "../lib/events";
import { BAA_SIGNATURE_NAME } from "../types";
import { useOpenCases } from "./api/useOpenCases";
import { useSignatures } from "./api/useSignatures";
import { CaseDetailsDialog } from "./CaseDetailsDialog";
import { HomeHealthBaaDialog } from "./HomeHealthBaaDialog";

export function FindCases() {
  const worker = useDefinedWorker();
  const { data: attendanceScoreProfile } = useGetAttendanceScoreProfile(worker?.userId);

  const [hideOnboardingBanner, setHideOnboardingBanner] = useState<boolean>(false);

  const { filters, setFilters } = useWorkerPreferences();

  const {
    data: cases,
    isLoading,
    isError,
    isRefetching,
    isSuccess,
    refetch: refetchOpenCases,
  } = useOpenCases(
    {
      filter: {
        longitude: worker.geoLocation?.coordinates[0],
        latitude: worker.geoLocation?.coordinates[1],
        maxDistanceInMiles: filters?.distance,
        qualifications: worker.licensesData.map((license) => license.qualification).join(","),
      },
    },
    { enabled: isDefined(filters) }
  );

  const {
    data: signaturesData,
    isLoading: isLoadingSignatures,
    isError: isErrorSignatures,
    isSuccess: isSuccessSignatures,
  } = useSignatures();
  const signModalState = useModalState(ModalStatus.OPEN);
  const history = useHistory();
  const hasSignedBaa =
    isSuccessSignatures &&
    signaturesData.data.some(
      (signature) => signature.attributes.documentName === BAA_SIGNATURE_NAME
    );

  useLogEffect(HOME_HEALTH_USER_EVENTS.OPEN_CASES_VIEW);

  const workerConfirmUIFlag = useCbhFlag(CbhFeatureFlag.HOME_HEALTH_WORKER_CONFIRM_UI, {
    defaultValue: {
      workerConfirmUI: false,
    },
  });
  const attendancePolicyLink = workerConfirmUIFlag.workerConfirmUI
    ? "https://docs.google.com/document/d/1HqY71vv_eMFOKfFLb-TFOx95U__cufj0H5Cy-M0VJ_g"
    : "https://docs.google.com/document/d/1OojQAWsysVI-8DCpkGk9FZqIDfa6-dX7vVQhm1nwtbU";

  const isClipboardScoreEnabled = useCbhFlag(CbhFeatureFlag.CLIPBOARD_SCORE_ROLLOUT, {
    defaultValue: false,
  });

  const reliabilityRankingExperiment = useReliabilityRankingExperiment({
    reliabilityScore: attendanceScoreProfile?.reliabilityScore,
    reliabilityScoreRank: attendanceScoreProfile?.reliabilityScoreRank,
  });

  const scoreType = getScoreType({
    isReliabilityRankingExperimentEnabled: reliabilityRankingExperiment.enabled,
    isClipboardScoreEnabled,
  });

  const { invitedVisitId } = useSearchParams();
  const theme = getTheme("light");
  return (
    <>
      <PullToRefresh onRefresh={refetchOpenCases} />
      {!hideOnboardingBanner && (
        <Banner
          severity="info"
          onClose={() => {
            setHideOnboardingBanner(true);
          }}
        >
          <Box textAlign="left">
            After booking, please chat with the HHA to receive EMR access. You should contact
            patient to schedule visits and submit notes within 24 hours of the visit. Once notes are
            approved, the agency will verify your visit to trigger payment.{" "}
            <ExternalLink color={theme.palette.common.black} to={attendancePolicyLink}>
              HH {scoreType} Policy.
            </ExternalLink>
          </Box>
        </Banner>
      )}

      <Stack width="100%" height="100%" alignItems="center" padding={3} overflow="auto">
        {isRefetching && (
          <LinearProgress
            sx={{ position: "absolute", top: 44, left: 0, width: "100%", height: 5, zIndex: 1 }}
          />
        )}
        {isSuccessSignatures && !hasSignedBaa && (
          <HomeHealthBaaDialog
            modalState={signModalState}
            onCancel={() => {
              localStorage.setItem("savedFindShiftsSegment", "calendar");
              localStorage.setItem("savedShiftBookingType", "per-diem");
              history.go(0);
            }}
          />
        )}
        <OpenShiftFilters
          // omitting licensesData from agent to hide license filter, because we want to show available cases for all licenses at once
          agent={{ ...worker, licensesData: [] }}
          disabled={isLoading || !isDefined(filters)}
          filters={filters ?? { distance: 150, license: "" }}
          setFilters={setFilters}
        />
        {(isLoading || isLoadingSignatures) && <CircularProgress size={64} />}
        {isError && <CardAlert label="Error loading open cases" severity="error" />}
        {isErrorSignatures && <CardAlert label="Error loading signatures" severity="error" />}
        {isSuccessSignatures && hasSignedBaa && isDefined(invitedVisitId) && (
          <CaseDetailsDialog visitId={invitedVisitId as string} />
        )}
        {isSuccess && isSuccessSignatures && hasSignedBaa && (
          <Stack spacing={2} width="100%">
            {cases.data.length === 0 ? (
              <Text variant="body2" align="center">
                There are no available cases nearby.
              </Text>
            ) : (
              cases.data.map((caseItem) => <OpenCase key={caseItem.id} caseItem={caseItem} />)
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
}
