import { formatLongDate } from "@clipboard-health/date-time";
import { ExternalLink, Image, Text, type UseModalState } from "@clipboard-health/ui-react";
import { Alert, Button, LinearProgress, Stack } from "@mui/material";
import { useGetAttendanceScoreProfile } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { getScoreType } from "@src/appV2/AttendanceScore/utils";
import { useCreateChatChannel } from "@src/appV2/Facilities/Chat/api/useCreateChatChannel";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { CommonDialog, useToast } from "@src/appV2/lib";
import { useReliabilityRankingExperiment } from "@src/appV2/ReliabilityRank/useReliabilityRankExperiment";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { startCase, toLower } from "lodash";

import lightningImage from "../assets/icons/lightning-blue.svg";
import { type Case, type Visit, VisitType } from "../types";
import { useBookVisit } from "./api/useBookVisit";

interface Props {
  visit: Visit;
  caseItem: Case;
  modalState: UseModalState;
}

export function BookVisitDialog(props: Props) {
  const { visit, caseItem, modalState } = props;
  const {
    mutateAsync: bookVisit,
    isLoading: isLoadingBook,
    isError: isErrorBook,
  } = useBookVisit(visit.id);
  const worker = useDefinedWorker();
  const { showSuccessToast } = useToast();
  const { data: attendanceScoreProfile } = useGetAttendanceScoreProfile(worker?.userId);

  const isAttendanceScoreEnabled = useCbhFlag(
    CbhFeatureFlag.HOME_HEALTH_BOOK_DIALOG_ATTENDANCE_ENABLED,
    { defaultValue: false }
  );

  const isClipboardScoreEnabled = useCbhFlag(CbhFeatureFlag.CLIPBOARD_SCORE_ROLLOUT, {
    defaultValue: false,
  });

  const reliabilityRankingExperiment = useReliabilityRankingExperiment({
    reliabilityScore: attendanceScoreProfile?.reliabilityScore,
    reliabilityScoreRank: attendanceScoreProfile?.reliabilityScoreRank,
  });

  const scoreType = getScoreType({
    isReliabilityRankingExperimentEnabled: reliabilityRankingExperiment.enabled,
    isClipboardScoreEnabled,
  });

  const { mutateAsync: createChatChannel, isLoading: isLoadingCreateChatChannel } =
    useCreateChatChannel();

  return (
    <CommonDialog
      modalState={modalState}
      title={
        <>
          <Image src={lightningImage} alt="Lightning bolt" height={32} marginRight={1} />
          Book {startCase(toLower(visit.attributes.type))} Visit
          {visit.attributes.type === VisitType.REGULAR && "s"}
        </>
      }
      actions={
        <Stack width="100%" spacing={1}>
          {isErrorBook && <Alert severity="error">Error booking visit</Alert>}
          <Button
            fullWidth
            disabled={isLoadingBook || isLoadingCreateChatChannel}
            sx={{ overflow: "hidden" }}
            variant="contained"
            onClick={async () => {
              try {
                await bookVisit({
                  data: { attributes: { bookedWorkerId: worker.userId }, type: "visit" },
                });
                showSuccessToast("Visit booked successfully");
                await createChatChannel({
                  agentId: worker.userId,
                  facilityId: caseItem.attributes.workplace.attributes.userId,
                });
                modalState.closeModal();
              } catch {
                // Silently handle error
              }
            }}
          >
            {(isLoadingBook || isLoadingCreateChatChannel) && (
              <LinearProgress
                color="info"
                sx={{ position: "absolute", bottom: 0, left: 0, width: "100%", height: "5px" }}
              />
            )}
            Book
          </Button>
          <Button fullWidth variant="outlined" onClick={modalState.closeModal}>
            Cancel
          </Button>
        </Stack>
      }
    >
      <Stack spacing={1}>
        <Text>Once you book this visit, it&apos;s all yours!</Text>
        {[VisitType.ADMISSION, VisitType.RESUMPTION_OF_CARE].includes(visit.attributes.type) && (
          <Text>
            That means you&apos;re committing to see this patient at their home before the deadline
            on <b>{formatLongDate(visit.attributes.deadline!)}</b>.
          </Text>
        )}
        {visit.attributes.type === VisitType.REGULAR && (
          <Text>
            That means <b>you&apos;re committing to see this patient repeatedly</b> until their
            recertification or discharge, at the specified weekly frequency.
          </Text>
        )}
        {isAttendanceScoreEnabled && (
          <Text>
            {scoreType} applies.{" "}
            <ExternalLink to="http://bit.ly/cbh-hh-attendance-score">Tap here</ExternalLink> for
            more information.
          </Text>
        )}
      </Stack>
    </CommonDialog>
  );
}
