import { type QueryClient } from "@tanstack/react-query";

import { CaseStatus } from "../../types";
import { getBookedCasesQueryKey } from "./useBookedCases";

export async function invalidateBookedCases(queryClient: QueryClient): Promise<void> {
  await Promise.all([
    queryClient.invalidateQueries(getBookedCasesQueryKey({ filter: { status: CaseStatus.OPEN } })),
    queryClient.invalidateQueries(
      getBookedCasesQueryKey({ filter: { status: CaseStatus.CLOSED } })
    ),
  ]);
}
