import { Image, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack } from "@mui/material";
import cbhLogoHorizontalImage from "@src/appV2/lib/assets/images/cbhLogoHorizontal.png";
import { type SupportContext } from "@src/appV2/support/constants";
import { HowToContactSupportLink } from "@src/appV2/support/HowToContactSupportLink";

export interface ErrorPageProps {
  title: string;
  supportContext: SupportContext;
  description?: string;
}

export function ErrorPage(props: ErrorPageProps) {
  const { title, description, supportContext } = props;

  return (
    <Stack
      alignItems="center"
      direction="column"
      justifyContent="center"
      textAlign="center"
      height="80vh"
    >
      <Box p={2}>
        <Text bold sx={{ mb: 2 }}>
          {title}
        </Text>

        {isDefined(description) && <Text>{description}</Text>}

        <Text sx={{ mb: 2 }}>
          If you keep having trouble, please reach out to our Support team.
        </Text>

        <HowToContactSupportLink supportContext={supportContext} />

        <Box>
          <Image height={120} src={cbhLogoHorizontalImage} alt="Clipboard Logo" />
        </Box>
      </Box>
    </Stack>
  );
}
