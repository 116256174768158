import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { Box, DialogContent, Stack } from "@mui/material";
import { FullScreenDialog, useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { FullScreenDialogTitle } from "@src/appV2/lib/Dialogs/FullScreen/DialogTitle";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useRef, useState } from "react";

import { HOME_HEALTH_USER_EVENTS } from "../lib/events";
import { BAA_SIGNATURE_NAME } from "../types";
import { useAddSignature } from "./api/useAddSignature";
import { BAA_VERSION, BaaText } from "./BaaText";
import { SignatureConfirmation } from "./SignatureConfirmation";

interface Props {
  modalState: UseModalState;
  onCancel: () => any;
}

export function HomeHealthBaaDialog(props: Props) {
  const worker = useDefinedWorker();

  const baaRef = useRef<HTMLDivElement>(null);
  const { modalState, onCancel } = props;
  const {
    mutateAsync: addSignature,
    isLoading: isLoadingSign,
    isError: isErrorSign,
  } = useAddSignature();
  const { showSuccessToast } = useToast();
  const [signature, setSignature] = useState("");

  return (
    <FullScreenDialog modalState={modalState}>
      <FullScreenDialogTitle onClose={onCancel}>
        Sign Business Associate Agreement
      </FullScreenDialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Title component="h3">
            You are going to be handling sensitive patient information. Make sure you read through
            the entire agreement before signing.
          </Title>
          <Box border="1px solid grey" p={1}>
            <BaaText ref={baaRef} contractorName={worker.name} contractorSignature={signature} />
          </Box>
          <SignatureConfirmation
            isLoading={isLoadingSign}
            isError={isErrorSign}
            onChange={setSignature}
            onSubmit={async () => {
              try {
                if (!baaRef.current) {
                  return;
                }

                await addSignature({
                  data: {
                    attributes: {
                      signedDocumentHTML: baaRef.current.innerHTML,
                      documentName: BAA_SIGNATURE_NAME,
                      version: BAA_VERSION,
                    },
                    type: "signature",
                  },
                });
                logEvent(HOME_HEALTH_USER_EVENTS.SIGNATURE_SUBMITTED, {
                  geolocation: {
                    longitude: worker.geoLocation?.coordinates[0],
                    latitude: worker.geoLocation?.coordinates[1],
                  },
                  documentName: BAA_SIGNATURE_NAME,
                  version: BAA_VERSION,
                });
                showSuccessToast("BAA signed successfully");
                modalState.closeModal();
              } catch {
                // Silently handle error
              }
            }}
            onCancel={onCancel}
          />
        </Stack>
      </DialogContent>
    </FullScreenDialog>
  );
}
