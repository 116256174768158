import { ModalStatus, Text, useModalState } from "@clipboard-health/ui-react";
import { Alert, Button, CircularProgress, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { useOpenCases } from "./api/useOpenCases";
import { OpenCase } from "./OpenCase";

interface Props {
  visitId: string;
}

export function CaseDetailsDialog(props: Props) {
  const { visitId } = props;
  const modalState = useModalState(ModalStatus.OPEN);
  const worker = useDefinedWorker();
  const {
    data: openCases,
    isLoading,
    isError,
    isSuccess,
  } = useOpenCases({
    filter: {
      visitId,
      qualifications: worker.licensesData.map((license) => license.qualification).join(","),
    },
  });

  return (
    <CommonDialog
      fullWidth
      modalState={modalState}
      title={<Text variant="h1">Home Health Case Details</Text>}
      actions={
        <Stack width="100%" spacing={1}>
          <Button fullWidth variant="outlined" onClick={modalState.closeModal}>
            Close
          </Button>
        </Stack>
      }
    >
      <Stack spacing={2} paddingY={2} alignItems="center">
        {isLoading && <CircularProgress size={64} />}
        {isError && <Alert severity="error">Error loading visit</Alert>}
        {isSuccess && openCases.data.length === 0 && (
          <Text variant="h3">This case is no longer available.</Text>
        )}
        {isSuccess && openCases.data.length > 0 && <OpenCase caseItem={openCases.data[0]} />}
      </Stack>
    </CommonDialog>
  );
}
